import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import Helpers from '../Config/Helpers';

function FullUserDetailModal({ show, handleClose, user }) {
    const [contents, setContents] = useState([]);

    useEffect(() => {
        if (show && user) {
            const token = localStorage.getItem('token'); // Adjust this line based on how you store the token

            axios.get(`${Helpers.apiUrl}auth/user/${user.id}/contents`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    setContents(response.data.data); // Adjusted to handle JSON:API response format
                })
                .catch(error => {
                    console.error('There was an error fetching the contents!', error);
                });
        }
    }, [show, user]);

    if (!user) return null;

    const isPublic = user.profile && user.profile.visibility === 'public';

    return (
        <Modal show={show} onHide={handleClose} centered dialogClassName="custom-modal-width">
            <Modal.Header closeButton>
                <Modal.Title className="text-lg font-semibold">{user.name}'s Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <div className="user-card flex items-center">
                    <figure className="avatar mr-3">
                        <img
                            src={user.profile && user.profile.profile_picture ? user.profile.profile_picture : './assets/images/profile-4.png'}
                            alt="User Profile"
                            className="rounded-circle"
                            style={{
                                width: '70px',
                                height: '70px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: '2px solid #ddd',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                marginRight: '10px'
                            }}
                        />
                    </figure>

                    <div className="user-info text-left">
                        <span className="user-name block text-lg font-bold text-gray-800">{user.name}</span>
                        {isPublic && <span className="user-email block text-sm text-gray-600">{user.email}</span>}
                        <span className="user-bio block mt-2 text-gray-700">{user.profile.bio}</span>
                        <span className="user-visibility block mt-2 text-gray-700">{user.profile.visibility}</span>
                        {isPublic && (
                            <div className="additional-info">
                                {/* Add other public details here */}
                                <span className="user-email block mt-2 text-gray-700">{user.email}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="user-contents mt-4">
                    <h5 className="text-lg font-semibold">Contents</h5>
                    {contents.length > 0 ? (
                        <ul>
                            {contents.map(content => (
                                <li key={content.id} className="content-item mt-2">
                                    <p><strong>Type:</strong> {content.type}</p>
                                    <p><strong>Description:</strong> {content.description}</p>
                                    <p><strong>Visibility:</strong> {content.content_visibility}</p>
                                    <p><strong>File:</strong> <a href={`${Helpers.basePath}/files/${content.file_path}`} target="_blank" rel="noopener noreferrer">{content.file_path}</a></p>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No contents available.</p>
                    )}

                    <style>
                        {`
                        .custom-modal-width .modal-dialog {
    max-width: 80%;
}`}
                    </style>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default FullUserDetailModal;
