import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../Config/Helpers';
import PreLoader from './PreLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const CommentList = () => {
  const { contentId } = useParams();
  const [content, setContent] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCommentsAndContent();
  }, [contentId]);

  const fetchCommentsAndContent = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}auth/get-comments?content_id=${contentId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data && response.data.data.length > 0) {
        // Assuming that the content details are same in all comment objects
        setContent(response.data.data[0].content);
        setComments(response.data.data);
      }
    } catch (error) {
      Helpers.toast('error', 'Failed to fetch comments');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-content right-chat-active">
      {loading ? <PreLoader /> : (
        <>
          <div className='row m-3 justify-content-center'>
            <div className='col-xl-10 col-xxl-10 col-lg-8'>
              <div className='row mb-2 text-start d-flex justify-content-between align-items-center'>
                <div className="col">
                  <h3 className="mb-0">All Comments of {content?.title ? content.title : 'This'}</h3>
                </div>
                <div className="col text-end">
                  <button className="btn btn-primary btn-sm text-white" onClick={() => navigate(-1)}>
                    <span><FontAwesomeIcon icon={faArrowLeft} /></span> Back
                  </button>
                </div>

              </div>
              {content && (
                <div className="card-body p-0 mb-3 rounded-3 overflow-hidden video-container" style={{ position: 'relative' }}>
                  {content.type === 'video' && (
                    <video
                      loop
                      controls
                      muted
                      className="w-100"
                      style={{ objectFit: 'contain', height: '400px' }}
                    >
                      <source src={content.file_path} type="video/mp4" />
                    </video>
                  )}
                  {content.type === 'photo' && (
                    <img
                      src={content.file_path}
                      alt="photo"
                      className="w-100 "
                      style={{ objectFit: 'contain', height: '400px' }}
                    />
                  )}
                  {content.type === 'music' && (
                    <audio controls className="w-100">
                      <source src={content.file_path} type="audio/mpeg" />
                    </audio>
                  )}
                </div>
              )}
              <ul className='p-3' style={{ marginBottom: '10px', borderRadius: '10px', backgroundColor: '#A27B1' }}>
                {comments.map((comment, i) => (
                  <div key={i} className="d-flex align-items-start mb-2" style={{ marginBottom: '10px' }}>
                    <figure className="avatar me-3">
                      <img
                        src={comment.user && comment.user.profile && comment.user.profile.profile_picture ? comment.user.profile.profile_picture : './assets/images/profile-4.png'}
                        alt="image"
                        className="shadow-sm rounded-circle w45"
                        style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                      />
                    </figure>
                    <div className="comment-content" style={{
                      background: '#f0f2f5',
                      borderRadius: '10px',
                      padding: '10px 15px',
                      maxWidth: '90%'
                    }}>
                      <h6 className="fw-700 text-gray-900 font-xssss mt-1 mb-1 text-start" style={{
                        margin: '0 0 5px 0',
                        fontSize: '14px',
                        fontWeight: '700'
                      }}>
                        {comment.user ? comment.user.name : 'Unknown User'}
                      </h6>
                      <p className="fw-500 text-gray-900 lh-26 font-xssss mb-0 text-start" style={{
                        margin: '0',
                        fontSize: '14px'
                      }}>
                        {comment.comment}
                      </p>
                      <div className="d-flex align-items-center mt-1 text-start" style={{ marginTop: '5px' }}>
                        <span className="d-block font-xssss fw-500 text-gray-500" style={{
                          fontSize: '12px',
                          color: '#65676b',
                        }}>
                          {comment.commented_at_human}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CommentList;
