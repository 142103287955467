import React, { useState, useEffect } from 'react';
import Helpers from '../Config/Helpers';
import axios from 'axios';
import { useNavigate, Link, useLocation } from 'react-router-dom';

function Sidebar() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Fetch user details on component mount
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${Helpers.apiUrl}auth/auth-user-detail`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.status === 200) {
          setUser(response.data.data);
        }
      } catch (error) {
        Helpers.toast('error', 'Failed to fetch user details');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${Helpers.apiUrl}auth/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 200) {
        Helpers.toast('success', 'Successfully Logged Out');
        localStorage.removeItem('token');
        localStorage.removeItem('user'); // Remove any other user-related data
        navigate('/login');
      }
    } catch (error) {
      Helpers.toast('error', 'Logout failed');
    }
  };

  return (
    <nav className="navigation scroll-bar bg-white">
      <div className="container ps-0 pe-0 d-flex flex-column mb-2">
        <div className="nav-content flex-grow-1">
          <div className="nav-wrap bg-white rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
            <div className="nav-caption fw-600 font-xssss text-grey-500 text-start"><span>New </span>Feeds</div>
            <ul className="mb-1 top-content">
              <li className="logo d-none d-xl-block d-lg-block"></li>
              <li>
                <Link
                  to="/"
                  className={`nav-content-bttn open-font ${location.pathname === '/' ||
                    location.pathname.startsWith('/search-results') ||
                    location.pathname.startsWith('/user') ||
                    location.pathname.startsWith('/video') ||
                    location.pathname.startsWith('/user-detail') ||
                    location.pathname.startsWith('/all-comments')
                    ? 'active' : ''
                    }`}
                >
                  <i className="feather-tv btn-round-md bg-blue-gradiant me-3"></i>
                  <span>Newsfeed</span>
                </Link>
              </li>
              <li>
                <Link to="/featured" className={`nav-content-bttn open-font ${location.pathname === '/featured' ? 'active' : ''}`}>
                  <i className="feather-list btn-round-md bg-red-gradiant me-3"></i>
                  <span>Featured</span>
                </Link>
              </li>
              <li>
                <Link to="/bookmarked-content" className={`nav-content-bttn open-font ${location.pathname === '/bookmarked-content' ? 'active' : ''}`}>
                  <i className="feather-save btn-round-md bg-red-gradiant me-3"></i>
                  <span>Saved</span>
                </Link>
              </li>
              <li>
                <Link to="/suggested-follows" className={`nav-content-bttn open-font ${location.pathname === '/suggested-follows' ? 'active' : ''}`}>
                  <i className="feather-users btn-round-md bg-red-gradiant me-3"></i>
                  <span>Suggested Follows</span>
                </Link>
              </li>
              {user && user.utype === 'artist' && (
                <>
               
                  <li>
                    <Link to="/upload-files" className={`nav-content-bttn open-font ${location.pathname === '/upload-files' ? 'active' : ''}`}>
                      <i className="feather-file btn-round-md bg-red-gradiant me-3"></i>
                      <span>Uploaded Files</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/author-profile" className={`nav-content-bttn open-font ${location.pathname === '/author-profile' || location.pathname.startsWith('/artist-videos') || location.pathname.startsWith('/artist-photos') || location.pathname.startsWith('/artist-music') ? 'active' : ''}`}>
                      <i className="feather-user btn-round-md bg-mini-gradiant me-3"></i>
                      <span>Author Profile</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="nav-wrap rounded-xxl shadow-xss pt-4 pb-1 mt-auto" style={{ backgroundColor: '#346E93' }}>
            <div className="nav-caption fw-600 font-xssss text-grey-300 text-start">
              <span></span> Account
            </div>
            <ul className="mb-1">
              <li className="logo d-none d-xl-block d-lg-block"></li>
              <li>
                <Link to="/default-settings" className={`nav-content-bttn open-font ${location.pathname === '/default-settings' || location.pathname === '/profile-settings' ? 'active' : ''}`}>
                  <i className="font-sm feather-settings me-3 text-grey-300"></i>
                  <span className='text-white'>Settings</span>
                </Link>
              </li>
              <li>
                <a href="#" className="nav-content-bttn open-font h-auto pt-2 pb-2" onClick={handleLogout}>
                  <i className="font-sm feather-log-out me-3 text-grey-300"></i>
                  <span className='text-white'>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
