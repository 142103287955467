import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import Helpers from '../../Config/Helpers';
import { debounce } from 'lodash';
import PreLoader from '../../Components/PreLoader';

const Featured = () => {
    const [key, setKey] = useState('visual_art');
    const [loading, setLoading] = useState(true);
    const [visualArtData, setVisualArtData] = useState([]);
    const [filmData, setFilmData] = useState([]);
    const [musicVideoData, setMusicVideoData] = useState([]);
    const [artistData, setArtistData] = useState([]);
    const videoRefs = useRef([]);
    const playPauseLocks = useRef([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token'); // Adjust this line based on how you store the token

                const visualArtResponse = await axios.get(`${Helpers.apiUrl}auth/fetch-visual-art`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setVisualArtData(visualArtResponse.data.data);

                const filmResponse = await axios.get(`${Helpers.apiUrl}auth/fetch-film`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setFilmData(filmResponse.data.data);

                const musicVideoResponse = await axios.get(`${Helpers.apiUrl}auth/fetch-music-video`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setMusicVideoData(musicVideoResponse.data.data);

                const artistResponse = await axios.get(`${Helpers.apiUrl}auth/fetch-featured-artists`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setArtistData(artistResponse.data.data);

                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch data', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleVideoHover = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            video.currentTime = 0; // Start playing from the beginning
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.play();
                } catch (error) {
                    console.log('Video play interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 500); // Increase debounce delay

    const handleVideoLeave = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.pause();
                } catch (error) {
                    console.log('Video pause interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 500); // Increase debounce delay

    const handleVideoPlayPause = async (index) => {
        const video = videoRefs.current[index];
        if (video && !playPauseLocks.current[index]) {
            playPauseLocks.current[index] = true;
            try {
                if (video.paused) {
                    await video.play();
                } else {
                    await video.pause();
                }
            } catch (error) {
                console.log('Video play/pause interrupted', error);
            } finally {
                playPauseLocks.current[index] = false;
            }
        }
    };
    const truncateText = (text, wordLimit) => {
        if (!text) return ''; // Return an empty string if text is null or undefined
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + ' ...';
        }
        return text;
    };
    
    const renderTabContent = (data) => {
        return (
            <div className='text-start mt-3'>
                <div className="grid">
                    {data.map((content, index) => (
                        <div key={index} className="card rounded-xxl border-0 hover-card">
                            <div className="card-body">
                            <h5>{truncateText(content.title, 4)}</h5>

                                {content.type === 'video' && (
                                    <video
                                        ref={el => videoRefs.current[index] = el}
                                        loop
                                        className="w-100 rounded-lg"
                                        controls
                                        muted
                                        onMouseOver={() => handleVideoHover(index)}
                                        onMouseLeave={() => handleVideoLeave(index)}
                                        onClick={() => handleVideoPlayPause(index)}
                                        style={{ objectFit: 'cover', height: '200px', transition: 'transform 0.3s ease-in-out', borderRadius:'10px'  }}
                                    >
                                        <source src={content.file_path} type="video/mp4" />
                                    </video>
                                )}
                                {content.type === 'photo' && (
                                    <img
                                        src={content.file_path}
                                        alt="photo"
                                        className="w-100"
                                        style={{ objectFit: 'cover', height: '200px', transition: 'transform 0.3s ease-in-out', borderRadius:'10px' }}
                                    />
                                )}
                                {content.type === 'music' && (
                                    <audio
                                        controls
                                        className="w-100 rounded-md audio-player"
                                        style={{ borderRadius:'10px' }}
                                    >
                                        <source src={content.file_path} type="audio/mpeg" />
                                    </audio>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <div>
                    <PreLoader />
                </div>
            ) : (
                <div className="main-content theme-dark-bg py-2 mt-3">
                    <div className="middle-sidebar-left mt-2">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <Tabs id="featured-tabs" activeKey={key} onSelect={(k) => setKey(k)} className="custom-tabs">
                                                <Tab eventKey="visual_art" title="Visual Art" className="custom-tab">
                                                    {renderTabContent(visualArtData)}
                                                </Tab>
                                                <Tab eventKey="film" title="Film" className="custom-tab">
                                                    {renderTabContent(filmData)}
                                                </Tab>
                                                <Tab eventKey="music" title="Music Video" className="custom-tab">
                                                    {renderTabContent(musicVideoData)}
                                                </Tab>
                                                <Tab eventKey="artists" title="Artists" className="custom-tab">
                                                    {renderTabContent(artistData)}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <style>
                {`
                .custom-tabs .nav-link {
                    border: none;
                    padding: 0.5rem 1rem;
                    margin-right: 0.25rem;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #4A5568;
                }
                .custom-tabs .nav-link.active {
                    border-bottom: 2px solid #3182CE;
                    color: #3182CE;
                }
                .grid {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                }
                .card {
                    flex: 1 1 calc(33.33% - 16px); /* Three cards in a row */
                    box-sizing: border-box;
                    padding: 16px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
                .card-body {
                    padding: 0;
                    margin: 0;
                }
                .video-card video {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
                .photo-card img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
                .music-card audio {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                }
                .hover-card {
                    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                }
                .hover-card:hover {
                    transform: scale(1.05);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1);
                }
                .description {
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* Number of lines to show */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }
                .audio-player {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                }
                `}
            </style>
        </div>
    );
};

export default Featured;
