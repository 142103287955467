import React, { useState, useEffect } from 'react';
import PreLoader from '../../Components/PreLoader';
import Helpers from '../../Config/Helpers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

export default function UploadFiles() {
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tagId, setTagId] = useState('');
    const [subCategoryId, setSubCategoryId] = useState('');
    const [tags, setTags] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [contentVisibility, setContentVisibility] = useState('');
    const [previewContent, setPreviewContent] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [contentsResponse, tagsResponse, subCategoriesResponse] = await Promise.all([
                    axios.get(`${Helpers.apiUrl}auth/author-content`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }),
                    axios.get(`${Helpers.apiUrl}auth/fetch-tags`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }),
                    axios.get(`${Helpers.apiUrl}auth/fetch-sub-categories`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    })
                ]);

                if (contentsResponse.status === 200) {
                    setContents(contentsResponse.data.data);
                }

                if (tagsResponse.status === 200) {
                    setTags(tagsResponse.data.data);
                }

                if (subCategoriesResponse.status === 200) {
                    setSubCategories(subCategoriesResponse.data.data);
                }
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                const response = await axios.delete(`${Helpers.apiUrl}auth/contents/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (response.status === 200) {
                    setContents(contents.filter(content => content.id !== id));
                    Helpers.toast('success', 'Data deleted successfully');
                }
            } catch (error) {
                console.error('Failed to delete content:', error);
                Helpers.toast('error', 'Failed to delete content');
            }
        }
    };

    const handlePreview = (content) => {
        setPreviewContent(content);
        setIsModalOpen(true);
    };

    const truncateDescription = (description, length) => {
        if (!description) return '';
        return description.length > length ? `${description.substring(0, length)}...` : description;
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="main-content bg-lightblue theme-dark-bg py-2">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/" className="d-inline-block mt-2">
                                            <i className="ti-arrow-left font-sm text-white"></i>
                                        </Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Uploaded Files</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className='text-center'>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {contents.length > 0 ? (
                                                    contents.map((content, index) => (
                                                        <tr key={content.id}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td className='text-start'>{truncateDescription(content.title || '', 50)}</td>
                                                            <td>{content.type}</td>
                                                            <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                                <button className="btn btn-primary btn-sm text-white" onClick={() => handlePreview(content)}>Preview</button>
                                                                <button className="btn btn-danger btn-sm text-white" onClick={() => handleDelete(content.id)} style={{ marginLeft: '10px' }}>Delete</button>
                                                            </td>



                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">No Data Found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {previewContent && (
                        <>
                            {previewContent.type === 'video' && (
                                <video controls className="w-100">
                                    <source src={previewContent.file_path} type="video/mp4" />
                                </video>
                            )}
                            {previewContent.type === 'photo' && (
                                <img
                                    src={previewContent.file_path}
                                    alt="photo"
                                    className="w-100"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '70vh',
                                        display: 'block',
                                        margin: 'auto'
                                    }}
                                />
                            )}
                            {previewContent.type === 'music' && (
                                <audio controls className="w-100">
                                    <source src={previewContent.file_path} type="audio/mpeg" />
                                </audio>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
