import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PreLoader from './PreLoader';

const FeaturedCard = () => {
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
        setLoading(false);
    };
    return (
        <>
            {loading && <PreLoader />}
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex position-relative" style={{ alignItems: 'stretch' }}>
                    <div style={{ flex: '1 0 50%', marginRight: '10px', display: 'flex' }}>
                        <img
                            src="./assets/images/featured-2.jpg"
                            alt="photo"
                            className="w-100"
                            style={{ objectFit: 'cover', height: '100%', borderRadius: '15px' }}
                            onLoad={handleImageLoad}
                        />

                    </div>
                    <div className="d-flex flex-column p-2 text-left" style={{ flex: '1 0 50%', height: '100%' }}>

                        <span className="mb-2 text-black">Visual Art</span>
                        <span className="mb-2 text-black">Film</span>
                        <span className="mb-2 text-black">Music Video</span>
                        <span className="mb-2 text-black">Artists</span>
                    </div>
                </div>
                {!loading && (
                    <div className="w-100 p-3">
                        <Link to="/featured" className="btn rounded-xxxl text-white w-100" style={{ background: '#346E93' }}>
                            Go To Featured
                        </Link>
                    </div>
                )}
            </div>

        </>
    );
};

export default FeaturedCard;
