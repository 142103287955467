import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Helpers from '../../Config/Helpers';
import PreLoader from '../../Components/PreLoader';
import ShareComponent from '../../Components/ShareComponent';
import ReactPlayer from 'react-player';

function ContentPage() {
    const { contentId } = useParams();
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState("");
    const [commentVisible, setCommentVisible] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [shareData, setShareData] = useState({});
    const [displayedComments, setDisplayedComments] = useState([]);
    const [showAllComments, setShowAllComments] = useState(false);

    const playerRef = useRef(null);
    const lastPauseTimeRef = useRef(0);
    const totalDurationRef = useRef(0);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/contents/${contentId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.status === 200) {
                    setContent(response.data.data);
                    await fetchComments(response.data.data.id);
                }
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch content');
            } finally {
                setLoading(false);
            }
        };

        fetchContent();
    }, [contentId]);

    const fetchComments = async (contentId) => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/get-comments?content_id=${contentId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setComments(response.data.data);
                setDisplayedComments(response.data.data.slice(0, 3)); // Show only first 3 comments
                // console.log('fetch comments ',response.data.data);
                
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch comments');
        }
    };

    const handleLike = async (contentId) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/like`, {
                content_id: contentId,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 201 || response.status === 200) {
                setContent(prevContent => {
                    if (prevContent.id === contentId) {
                        return {
                            ...prevContent,
                            user_liked: true,
                            likes_count: prevContent.likes_count + 1
                        };
                    }
                    return prevContent;
                });
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to post like');
        }
    };

    const handleUnlike = async (contentId) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/unlike`, {
                content_id: contentId,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setContent(prevContent => {
                    if (prevContent.id === contentId) {
                        return {
                            ...prevContent,
                            user_liked: false,
                            likes_count: prevContent.likes_count - 1
                        };
                    }
                    return prevContent;
                });
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to unlike content');
        }
    };

    const handleLikeToggle = (contentId, userLiked) => {
        if (userLiked) {
            handleUnlike(contentId);
        } else {
            handleLike(contentId);
        }
    };

    const handlePlay = () => {
        lastPauseTimeRef.current = playerRef.current.getCurrentTime();
        totalDurationRef.current = 0;
    };

    const handlePause = async () => {
        if (playerRef.current) {
            const currentTime = playerRef.current.getCurrentTime();
            if (lastPauseTimeRef.current !== null) {
                const pausedDuration = currentTime - lastPauseTimeRef.current;
                await savePlaybackTime(pausedDuration);
            }
            lastPauseTimeRef.current = currentTime;
        }
    };

    const handleEnded = async () => {
        if (playerRef.current) {
            const videoLength = playerRef.current.getDuration();
            const remainingDuration = videoLength - totalDurationRef.current;
            totalDurationRef.current += remainingDuration;
        }
    };

    const savePlaybackTime = async (duration) => {
        const roundedDuration = Math.max(1, Math.round(duration * 100) / 100);
        if (roundedDuration > 0) {
            try {
                await axios.post(`${Helpers.apiUrl}auth/content/save-duration`, {
                    content_id: contentId,
                    duration: roundedDuration
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } catch (error) {
                Helpers.toast('error', 'Failed to save playback time');
            }
        }
    };

    const toggleCommentSection = () => {
        setCommentVisible(!commentVisible);
    };

    const handleCommentChange = (e) => {
        setCommentText(e.target.value);
    };

    const handleCommentSubmit = async () => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/comments`, {
                content_id: contentId,
                comment: commentText
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 201) {
             
                const newComment = { ...response.data.data };

                setComments((prev) => [newComment, ...prev]);
                setDisplayedComments((prev) => [newComment, ...prev].slice(0, showAllComments ? undefined : 3));
                setCommentText("");
                setCommentVisible(true);
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to post comment');
        }
    };

    const handleShowAllComments = () => {
        setDisplayedComments(comments);
        setShowAllComments(true);
    };

    const handleShare = (content) => {
        const shareData = {
            title: content.description || 'Content Title',
            text: content.description || 'Content Description',
            url: `${window.location.origin}/content/${content.id}`
        };
        setShareData(shareData);
        setShowShareModal(true);
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className='row mt-3 justify-content-center'>
          
                    <div className="col-xl-8 col-xxl-9 col-lg-8">
                        <div className="card w-100 shadow-none bg-white bg-transparent-card border-0 p-0 mb-0 rounded-xxl">
                        <div className="card-body p-0 rounded-xxl">
                            {content.type === 'video' && (
                                <ReactPlayer
                                    className="float-right w-100 rounded-xxl"
                                    url={content.file_path}
                                    controls
                                    width="100%"
                                    height="400px"
                                    ref={playerRef}
                                    onPlay={handlePlay}
                                    onPause={handlePause}
                                    onEnded={handleEnded}
                                />
                            )}
                            {content.type === 'photo' && (
                                <img
                                    src={content.file_path}
                                    alt="photo"
                                    className="w-100 rounded-xxl"
                                    style={{ objectFit: 'cover' }}
                                />
                            )}
                            {content.type === 'music' && (
                                <audio
                                    controls
                                    className="w-100 rounded-xxl"
                                >
                                    <source src={content.file_path} type="audio/mpeg" />
                                </audio>
                            )}
                            <div className="card-body text-start p-4">
                                <p>{content.description || 'Content Description'}</p>
                                <div className="d-flex align-items-center mt-4">
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleLikeToggle(content.id, content.user_liked); }} className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                                        <i className={`feather-heart text-white ${content.user_liked ? 'bg-red-gradiant' : 'bg-grey'} me-1 btn-round-xs font-xss`}></i>
                                        {content.likes_count} {content.likes_count === 1 ? 'Like' : 'Likes'}
                                    </a>
                                    <a href="#" onClick={(e) => { e.preventDefault(); toggleCommentSection(); }} className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                                        <i className="feather-message-circle text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i>
                                        {commentVisible ? `${comments.length} Hide` : `${comments.length} Comment`}
                                    </a>
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleShare(content); }} className="ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
                                        <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i>
                                        Share
                                    </a>
                                </div>
                                {commentVisible && (
                                    <div className="card-body p-0 mt-3">
                                        <textarea
                                            className="form-control comment-textarea"
                                            rows="3"
                                            placeholder="Write your comment"
                                            value={commentText}
                                            onChange={handleCommentChange}
                                            style={{
                                                borderRadius: '18px',
                                                padding: '10px 15px',
                                                border: '1px solid #ccc',
                                                width: '100%',
                                                lineHeight: '1.5',
                                                height: '80px'
                                            }}
                                        ></textarea>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <button
                                                className="btn btn-primary mt-2"
                                                onClick={() => handleCommentSubmit()}
                                                style={{
                                                    backgroundColor: '#1877f2',
                                                    color: '#fff',
                                                    borderRadius: '18px',
                                                    padding: '10px 20px',
                                                    border: 'none'
                                                }}
                                            >
                                                Post Comment
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {commentVisible && (
                                    <div className="mt-4">
                                        {displayedComments.map((comment, i) => (
                                            <div key={i} className="d-flex align-items-start mb-3">
                                                <figure className="avatar me-3">
                                                    <img
                                                        src={comment.user && comment.user.profile && comment.user.profile.profile_picture ? comment.user.profile.profile_picture : '/assets/images/profile-4.png'}
                                                        alt="image"
                                                        className="shadow-sm rounded-circle w45"
                                                        style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                                    />
                                                </figure>
                                                <div className="comment-content" style={{
                                                    background: '#f0f2f5',
                                                    borderRadius: '18px',
                                                    padding: '10px 15px',
                                                    maxWidth: '90%'
                                                }}>
                                                    <h6 className="fw-700 text-grey-900 font-xssss mt-1 mb-1">
                                                        {comment.user ? comment.user.name : 'Unknown User'}
                                                    </h6>
                                                    <p className="fw-500 text-grey-500 lh-26 font-xssss mb-0">
                                                        {comment.comment}
                                                    </p>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <span className="d-block font-xssss fw-500 text-grey-500">
                                                            {comment.created_at_human}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {!showAllComments && comments.length > 3 && (
                                            <div className="text-end">
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={handleShowAllComments}
                                                    style={{
                                                        backgroundColor: '#1877f2',
                                                        color: '#fff',
                                                        borderRadius: '5px',
                                                        padding: '5px 10px',
                                                        border: 'none'
                                                    }}>
                                                    Show all {comments.length} comments
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <>
                <ShareComponent show={showShareModal} handleClose={() => setShowShareModal(false)} shareData={shareData} />
            </>
        </div>
    );
}

export default ContentPage;
