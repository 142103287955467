import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../Config/Helpers';

function UserDetailModal({ show, handleClose, user }) {
    const [isFollowing, setIsFollowing] = useState(false);
    const [followersCount, setFollowersCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);
    const baseUrl = Helpers.basePath;
    const apiUrl = Helpers.apiUrl;
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            checkFollowStatus();
            fetchFollowersCount();
            fetchFollowingCount();
        }
    }, [user]);

    const checkFollowStatus = async () => {
        try {
            const response = await axios.get(`${apiUrl}auth/followers/${user.id}/status`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setIsFollowing(response.data.isFollowing);
        } catch (error) {
            console.error('There was an error checking the follow status!', error);
        }
    };

    const fetchFollowersCount = async () => {
        try {
            const response = await axios.get(`${apiUrl}auth/followers/${user.id}/followers-count`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setFollowersCount(response.data.count);
        } catch (error) {
            console.error('There was an error fetching the followers count!', error);
        }
    };

    const fetchFollowingCount = async () => {
        try {
            const response = await axios.get(`${apiUrl}auth/followers/${user.id}/following-count`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setFollowingCount(response.data.count);
        } catch (error) {
            console.error('There was an error fetching the following count!', error);
        }
    };

    const handleFollow = async () => {
        try {
            if (isFollowing) {
                await axios.delete(`${apiUrl}auth/followers/${user.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                Helpers.toast('success', 'Unfollowed successfully');
            } else {
                await axios.post(`${apiUrl}auth/followers`, { userId: user.id }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                Helpers.toast('success', 'Followed successfully');
            }
            setIsFollowing(!isFollowing);
            fetchFollowersCount(); // Update followers count after follow/unfollow
            fetchFollowingCount(); // Update following count after follow/unfollow
        } catch (error) {
            console.error('There was an error following/unfollowing the user!', error);
            Helpers.toast('error', 'There was an error following/unfollowing the user!');
        }
    };

    const handleViewDetails = () => {
        handleClose();
        const authUser = JSON.parse(localStorage.getItem('user'));
        const userId = authUser?.data?.id;
        if (userId === user.id) {
            navigate('/author-profile');
        } else {
            navigate(`/user-detail/${user.id}`);

        }
      
    };

    if (!user) return null;

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-lg font-semibold">{user.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="user-card flex items-center">
                    <figure className="avatar mr-3">
                        <img
                            src={user.profile && user.profile.profile_picture ? user.profile.profile_picture : './assets/images/profile-4.png'}
                            alt="Profile"
                            style={{
                                width: '70px',
                                height: '70px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: '2px solid #ddd',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                marginRight: '10px'
                            }}
                        />
                    </figure>
                    <div className="user-info text-left">
                        <span className="user-name block text-lg font-bold text-gray-800">{user.name}</span>
                        <span className="user-email block text-sm text-gray-600">{user.email}</span>
                        <span className="user-bio block mt-2 text-gray-700">{user.profile.bio}</span>
                        <div className="user-following-followers flex items-center mt-2 text-sm text-gray-500">
                            <span style={{ marginRight: '20px' }}><strong>{followersCount}</strong> followers</span>
                            <span ><strong>{followingCount}</strong> following</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {!isFollowing ? (
                    <Button variant="primary" className="bg-blue-500 text-white hover:bg-blue-700" onClick={handleFollow}>
                        Follow
                    </Button>
                ) : (
                    <>
                        <Button variant="primary" className="bg-blue-500 text-white hover:bg-blue-700" onClick={handleFollow}>
                            Unfollow
                        </Button>
                        <Button variant="info" className="bg-blue-500 text-white hover:bg-blue-700" onClick={handleViewDetails}>
                            View Details
                        </Button>
                    </>
                )}

            </Modal.Footer>

        </Modal>
    );
}

export default UserDetailModal;
